import "react-toastify/dist/ReactToastify.css";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import HomeLayout from "./Layout/HomeLayout";
import Home from "./pages/Home";
import ProductDetails from "./pages/ProductDetails";
import AddToCart from "./pages/AddToCart";
import Checkout from "./pages/Checkout";
import SuccessPage from "./pages/SuccessPage";
import TermsConditions from "./pages/TermsConditions";
import ReturnPolicy from "./pages/ReturnPolicy";
import SupportPolicy from "./pages/SupportPolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import About from "./pages/About";
import Contact from "./pages/Contact";
import SellCondition from "./pages/SellCondition";
import BlogPage from "./pages/BlogPage";
import CategoryPage from "./pages/CategoryPage";
import NotFound from "./pages/NotFound";
import { useSelector } from "react-redux";
import LandingLayout from "./Layout/LandingLayout";
import LandingPage from "./pages/LandingPage";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import UserLayout from "./Layout/UserLayout";
import UserProfile from "./pages/user/UserProfile";
import UserOrder from "./pages/user/UserOrder";
import SubCategoryPage from "./pages/SubCategoryPage";

function App() {
  const { cartItems: data } = useSelector((state) => state.cart);
  const { userAndToken: userData } = useSelector((state) => state.auth);
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomeLayout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/productdetails/:id",
          element: <ProductDetails />,
        },
        {
          path: "/addtocart",
          element: <AddToCart />,
        },
        {
          path: "/checkout",
          element: data.length > 0 ? <Checkout /> : <NotFound />,
        },
        {
          path: "/successpage",
          element: <SuccessPage />,
        },
        {
          path: "/terms",
          element: <TermsConditions />,
        },
        {
          path: "/returnpolicy",
          element: <ReturnPolicy />,
        },
        {
          path: "/supportpolicy",
          element: <SupportPolicy />,
        },
        {
          path: "/privacypolicy",
          element: <PrivacyPolicy />,
        },
        {
          path: "/about-us/:id",
          element: <About />,
        },
        {
          path: "/contact-us/:id",
          element: <Contact />,
        },
        {
          path: "/sellcondition",
          element: <SellCondition />,
        },
        {
          path: "/blogs/:id",
          element: <BlogPage />,
        },
        {
          path: "/categorypage/category/:id",
          element: <CategoryPage />,
        },
        {
          path: "/subcategory/:id",
          element: <SubCategoryPage />,
        },
        {
          path: "*",
          element: <NotFound />,
        },

        {
          path: "/login",
          element: userData ? <Navigate to="/" /> : <Login />,
        },
        {
          path: "/register",
          element: userData ? <Navigate to="/" /> : <SignUp />,
        },
      ],
    },
    {
      path: "/landingpage",
      element: <LandingLayout />,
      children: [
        {
          path: "/landingpage/:id",
          element: <LandingPage />,
        },
      ],
    },
    {
      path: "/user",
      element: <UserLayout />,
      children: [
        {
          index: true,
          element: <Navigate to={`/user/profile`} replace />,
        },
        {
          path: `/user/profile`,
          element: userData ? <UserProfile /> : <Navigate to="/" />,
        },
        {
          path: "/user/order",
          element: userData ? <UserOrder /> : <Navigate to="/" />,
        },
      ],
    },
  ]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
