const OfferBanner = () => {
  return (
    <div className="container mx-auto my-10">
      <div className="banner-image rounded-md overflow-hidden ">
        <img src="/offerbanner.jpeg" alt="banner" />
      </div>
    </div>
  );
};

export default OfferBanner;
