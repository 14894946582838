import React from "react";
import SectionTitle from "./SectionTitle";
import { useSelector } from "react-redux";
import {
  A11y,
  Navigation,
  HashNavigation,
  Pagination,
  Scrollbar,
  Grid,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/hash-navigation";

import Card from "./Card";

const ProductCard = () => {
  const { items: data } = useSelector((state) => state.products);

  return (
    <div className="wrapper">
      <SectionTitle title={"Show Our Product"} />

      <Swiper
        modules={[
          Navigation,
          Pagination,
          Scrollbar,
          A11y,
          HashNavigation,
          Grid,
        ]}
        spaceBetween={25}
        // slidesPerView={6}
        navigation
        hashNavigation
        scrollbar={{ draggable: true }}
        parallax={true}
        grabCursor={true}
        loop={true}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 10,
          },

          330: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 25,
          },

          1024: {
            slidesPerView: 5,
            spaceBetween: 25,
          },
          1440: {
            slidesPerView: 5,
            spaceBetween: 25,
          },
        }}
      >
        {data?.map((product) => (
          <SwiperSlide key={product.id}>
            <Card key={product.id} product={product} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ProductCard;
